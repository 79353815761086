<template>
  <div class="promo healthcare">
    <div class="promo-title">Northern Police Healthcare</div>
    <div class="promo-summary">{{ page.summary}}</div>
    <div class="promo-links">
      <router-link to="/healthcare/benefits">Benefits</router-link>
      <router-link to="/healthcare/costs">Costs</router-link>
      <router-link to="/healthcare/wording">Documents, Downloads &amp; Links</router-link>
      <router-link class="apply" to="/healthcare/apply">Apply Now</router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: ["page"]
}
</script>
