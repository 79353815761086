<template>
  <section class="content inner">
    <healthcare-header :page="page"></healthcare-header>
    <div class="text">
      <div class="video-wrapper" v-if="page.video_id && page.video_id.length">
        <div class="video" style="padding:56.25% 0 0 0;position:relative;">
          <iframe
            :src="'https://player.vimeo.com/video/' + page.video_id + '?color=ff9933&title=0&byline=0&portrait=0'"
            allow="autoplay; fullscreen"
            allowfullscreen
            frameborder="0"
            style="position:absolute;top:0;left:0;width:100%;height:100%;"
          ></iframe>
        </div>
      </div>
      <span v-html="page.main_text"></span>

      <downloads />
    </div>
  </section>
</template>

<script>
import HealthcareHeader from "@/components/HealthcareHeader.vue"
import api from "@/services/api"

export default {
  metaInfo() {
    return {
      title: this.$store.state.page.title || "South Yorkshire Police Federation",
      meta: [
        { name: "description", content: this.$store.state.page.meta_desc },
        { name: "keywords", content: this.$store.state.page.meta_keywords }
      ]
    }
  },
  components: {
    HealthcareHeader
  },
  created() {
    api.getPageByURL(this.$route.path)
  },
  computed: {
    page() {
      return this.$store.state.page
    }
  }
}
</script>
